<template>
  <div class="multi-project-modal">
    <div class="multi-project-modal-wrapper">
      <div class="multi-project-header">
        <div class="title-modal border-bottom-solid">
          <span class="font-sz-14 bold-700 font-text color-black">
            {{
              $i18n.locale === 'fr'
                ? `Modifier un groupe de prospects ( ${data.length} projets )`
                : `Modify a prospect group ( ${data.length} projects )`
            }}
          </span>
        </div>
      </div>
      <div
        class="form-multi-project"
        :class="{
          'height-scroll':
            getProjectsError || getProjectsProcessing || updateSuccess
        }"
      >
        <v-form ref="formMasseProjet" class="form-masse mt-2">
          <v-autocomplete
            class="select-projet-masse mt-2"
            color="#5C2DD3"
            :placeholder="$t('search')"
            item-color="#5C2DD3"
            v-model="dataToUpdate.step"
            @input="handleEtapeChange"
            :items="getEtapes"
            :persistent-placeholder="true"
            :label="$t('etape')"
            item-text="name"
            dense
            :loading="getEtapesLoading"
            return-object
            item-value="id"
            :no-data-text="$t('noDataOptionMsg', { option: $t('etape') })"
            outlined
            :menu-props="{
              bottom: true,
              offsetY: true
            }"
          >
          </v-autocomplete>
          <v-autocomplete
            v-if="currentStages"
            class="select-projet-masse mt-2"
            :clearable="true"
            color="#5C2DD3"
            :placeholder="$t('search')"
            item-color="#5C2DD3"
            v-model="dataToUpdate.stage"
            @input="handleStageChange"
            :items="currentStages"
            :persistent-placeholder="true"
            :label="$t('categoriesStage')"
            item-text="name"
            dense
            :loading="getEtapesLoading"
            return-object
            item-value="id"
            :no-data-text="
              $t('noDataOptionMsg', { option: $t('categoriesStage') })
            "
            outlined
            :menu-props="{
              bottom: true,
              offsetY: true
            }"
          >
          </v-autocomplete>
          <v-autocomplete
            v-if="dataToUpdate.stage && currentSubStages"
            class="select-projet-masse mt-2"
            :clearable="true"
            color="#5C2DD3"
            :placeholder="$t('search')"
            item-color="#5C2DD3"
            v-model="dataToUpdate.subStage"
            @input="handleMultiSelectInput"
            :items="currentSubStages"
            return-object
            :persistent-placeholder="true"
            :label="$t('stade')"
            item-text="name"
            dense
            item-value="id"
            :no-data-text="$t('noDataOptionMsg', { option: $t('stade') })"
            outlined
            :menu-props="{
              bottom: true,
              offsetY: true
            }"
          >
          </v-autocomplete>
        </v-form>
        <div class="deleted-plus-projet mt-2 mb-3 pb-3">
          <v-divider></v-divider>
          <div
            class="delete-Multi-project mb-1 mt-1"
            v-if="currentUser && currentUser.isSuper"
          >
            <v-btn icon @click.prevent="handleDeleteMultiProject">
              <font-awesome-icon
                class="img-h-14 color-red font-text cursor-pointer"
                icon="trash"
              />
            </v-btn>
            {{
              $i18n.locale === 'fr'
                ? `Supprimez les ${data.length} projets`
                : `Delete ${data.length} projects`
            }}
          </div>
          <v-divider></v-divider>
        </div>
        <!-- END Delete MultiProject Modal -->
        <div class="multi-project-footer">
          <div v-if="getProjectsProcessing" class="loading">
            {{ $t('loading') }}
          </div>
          <div v-if="localError" class="error-msg">
            {{ localError }}
          </div>
          <div v-if="localError2" class="error-msg message mt-1 ml-2">
            {{ localError2 }}
          </div>
          <div v-if="getProjectsError" class="error-msg">
            <ul v-if="Array.isArray(getProjectsError)">
              <li v-for="(e, index) in getProjectsError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getProjectsError }}</span>
          </div>
          <div
            v-if="updateSuccess && !getProjectsProcessing"
            class="success-msg ml-2"
          >
            {{
              $i18n.locale === 'fr'
                ? ` ${data.length} projets ont été modifié !`
                : ` ${data.length} projects have been modified !`
            }}
          </div>
        </div>
      </div>

      <!-- ACTIONS BTN -->
      <div class="action-multi-projet float-right mr-3 mt-3 mb-3">
        <v-btn color="#5C2DD3" dark :loading="loading" @click="updateProjects">
          {{ $i18n.locale === 'fr' ? 'Enregistrer' : 'Save' }}
          <v-icon class="ml-1">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn
          text
          class="ml-3"
          @click="handleClose"
          variant="outline-secondary"
        >
          {{ $t('btnCancel') }}
        </v-btn>
      </div>
    </div>
    <!-- Delete MultiProject Modal -->
    <v-dialog
      v-model="deletedMultiProjet"
      max-width="600"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? `Supprimer les ${data.length} projets`
                : `Delete ${data.length} projects`
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="deletedMultiProjet = false"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <div>
            {{ $t('msgdeleted', { msg: $i18n.locale === 'fr' ? 'les' : '' }) }}
            <strong>
              {{ data.length ? data.length : '' }}
            </strong>
            {{ $i18n.locale === 'fr' ? 'projets' : 'projects' }} ?
          </div>
          <ul>
            <li
              v-for="projets in data"
              :key="projets.id"
              class="titles-projets"
            >
              {{ projets.prenom + '   ' }} {{ projets.nom }}
            </li>
          </ul>
          <div class="color-red mt-2 mb-2 flex-center">
            <font-awesome-icon
              class="color-red img-h-19 mr-2"
              icon="exclamation-circle"
            />
            {{
              $i18n.locale === 'fr'
                ? 'La suppression sera définitive !'
                : 'The deletion will be definitive !'
            }}
          </div>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getProjectsProcessing" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getProjectsError" class="error-msg">
              <ul v-if="Array.isArray(getProjectsError)">
                <li v-for="(e, index) in getProjectsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getProjectsError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="doDeleteMultiProject"
            :loading="getProjectsProcessing"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="deletedMultiProjet = false" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'multi-project-modal',
  props: {
    data: {
      required: true
    },
    type: {
      required: true
    },
    etape: {
      required: true
    }
  },
  components: {},
  data() {
    return {
      loading: false,
      deletedMultiProjet: false,
      updateSuccess: null,
      localError: null,
      localError2: null,
      dataToUpdate: {
        stage: null,
        step: null,
        subStage: null
      },
      currentStages: []
    }
  },
  methods: {
    ...mapActions([
      'updateProjectsMultiple',
      'deleteProjectsMultiple',
      'resetErrorProject',
      'fetchEtapeSubstage',
      'fetchEtapeStage'
    ]),
    async updateProjects() {
      this.resetMessages()
      this.resetErrorProject()
      if (this.dataToUpdate.step && !this.dataToUpdate.stage) {
        this.localError =
          this.$i18n.locale === 'fr'
            ? 'La catégorie du stade est obligatoire'
            : 'The stage category is required'
        return
      }
      if (this.dataToUpdate.stage && !this.dataToUpdate.subStage) {
        this.localError =
          this.$i18n.locale === 'fr'
            ? 'Le stade est obligatoire'
            : 'The stage is required'
        return
      }
      if (!this.dataToUpdate.step && !this.dataToUpdate.stage) {
        this.localError2 =
          this.$i18n.locale === 'fr'
            ? 'Aucun changement à faire'
            : 'No changes to be made'
        return
      }
      const data = {
        projets: this.data.map(item => item.id)
      }
      if (this.dataToUpdate.step) {
        data.step_id = this.dataToUpdate.step.id
      }
      if (this.dataToUpdate.stage) {
        data.cstage_id = this.dataToUpdate.stage.id
      }
      if (this.dataToUpdate.subStage) {
        data.csubstage_id = this.dataToUpdate.subStage.id
      }
      const response = await this.updateProjectsMultiple({
        data,
        projects: this.data
      })
      if (response) {
        this.updateSuccess = true
        this.resetData()
        this.$emit('updateMultiChampsProjet')
      }
    },
    async handleStageChange(stage) {
      this.dataToUpdate.subStage = null
      if (stage) {
        await this.fetchEtapeSubstage({
          cstage_id: stage.id,
          stage: stage
        })
      }
      this.resetMessages()
    },
    async handleEtapeChange() {
      this.dataToUpdate.stage = null
      await this.fetchEtapeStage({
        step_id: this.dataToUpdate.step.id,
        etape: this.dataToUpdate.step
      }).then(() => {
        if (this.dataToUpdate.step) {
          const selectedStepId = this.dataToUpdate.step.id
          const selectedStep = this.getEtapes.find(
            step => step.id === selectedStepId
          )

          if (selectedStep) {
            // Mettre à jour les étapes actuelles en fonction de la première liste déroulante
            this.currentStages = selectedStep.stages || []
          } else {
            this.currentStages = []
          }
        } else {
          this.currentStages = []
        }
      })
    },
    handleMultiSelectInput() {
      this.resetMessages()
    },
    resetMessages() {
      this.localError = null
      this.localError2 = null
      this.updateSuccess = null
    },
    resetData() {
      this.dataToUpdate = {
        stage: null,
        subStage: null,
        step: null
      }
    },
    handleDeleteMultiProject() {
      this.resetErrorProject()
      this.deletedMultiProjet = true
    },
    handleClose() {
      this.resetErrorProject()
      this.$emit('close')
    },
    async doDeleteMultiProject() {
      const response = await this.deleteProjectsMultiple({
        projects: this.data
      })
      if (response) {
        this.deletedMultiProjet = false
      }
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsProcessing',
      'getProjectsError',
      'getEtapes',
      'currentUser',
      'getEtapesSubstageLoading',
      'getEtapesLoading'
    ]),
    currentSubStages: function() {
      if (!this.dataToUpdate.stage) {
        return null
      }
      if (
        this.dataToUpdate.stage.subStages &&
        this.dataToUpdate.stage.subStages.length
      ) {
        return this.dataToUpdate.stage.subStages
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.multi-project-modal {
  position: fixed;
  height: calc(100% - 0px);
  width: 500px;
  top: 0px;
  right: 0;
  z-index: 101;

  .multi-project-modal-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.32);
    // padding: 30px;
    font-size: 13px;
    color: #5e5e5e;
    .title-modal {
      padding: 20px;
      background-color: #f6f6f6;
    }

    .form-multi-project {
      padding: 20px;
      position: relative;
      width: 100%;
      overflow: hidden;
      height: calc(100vh - 170px);
      .form-masse {
        overflow: auto;
        height: calc(100vh - 350px);
        padding-right: 10px;
      }
      .form-masse::-webkit-scrollbar {
        height: 7px;
        width: 7px;
      }
      .form-masse::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #80808054;
        border-radius: 10px;
      }
      .form-masse::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 10px;
      }

      .form-masse::-webkit-scrollbar-thumb:hover {
        background: #888;
      }
      &.height-scroll {
        height: calc(100vh - 140px);
        overflow: hidden;
      }
    }
  }
}
.multi-project-footer {
  padding: 0px 20px;
}
.delete-Multi-project {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.deleted-plus-projet {
  &.padding {
    padding-top: 15px;
  }
}
</style>
<style lang="scss">
.v-autocomplete__content {
  max-width: 452px !important;
}
</style>
